// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  background-color: #1a1a1a;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.section-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ff6f61;
}

.contact-details {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-info p {
  margin: 10px 0;
}

.contact-info a {
  color: #ff6f61;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-info svg {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Contact.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".contact {\n  background-color: #1a1a1a;\n  color: #fff;\n  padding: 60px 20px;\n  text-align: center;\n  min-height: 50vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.container {\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.section-title {\n  font-family: 'Orbitron', sans-serif;\n  font-size: 2.5em;\n  margin-bottom: 20px;\n  color: #ff6f61;\n}\n\n.contact-details {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.2em;\n  line-height: 1.6;\n  margin-bottom: 20px;\n}\n\n.contact-info p {\n  margin: 10px 0;\n}\n\n.contact-info a {\n  color: #ff6f61;\n  text-decoration: none;\n}\n\n.contact-info a:hover {\n  text-decoration: underline;\n}\n\n.contact-info svg {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
