import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './About.css';

const skills = [
  { name: 'Python', level: 95 },
  { name: 'Django', level: 90 },
  { name: 'React', level: 85 },
  { name: 'Node.js', level: 80 },
  { name: 'SQL', level: 90 },
  { name: 'NoSQL', level: 85 },
  { name: 'Machine Learning', level: 80 },
  { name: 'AI', level: 75 },
  { name: 'Docker', level: 90 },
  { name: 'Kubernetes', level: 85 },
  { name: 'AWS', level: 80 },
  { name: 'Azure', level: 80 },
  { name: 'Microservices', level: 85 },
  { name: 'DevOps', level: 90 },
  { name: 'Git', level: 95 },
  { name: 'Agile Methodologies', level: 90 },
];

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2 className="section-title">About Me</h2>
        <div className="content">
          <p>Hi, I'm Jeet Padhya, a passionate and experienced software engineer with over a decade of experience in various aspects of software engineering, big data analytics, and full-stack development. Currently, I am the Head of Engineering at Kreditserve, where I lead a team of talented engineers to develop and deploy scalable and secure fintech solutions.</p>

          <h3>Experience</h3>
          <p>At Kreditserve, I oversee the entire product development lifecycle, ensuring our solutions are robust, scalable, and meet the highest security standards. Previously, I served as a Software Engineering Manager at Haptik, where I managed a team responsible for implementing scalable solutions for our clients.</p>

          <h3>Education</h3>
          <p>I hold a Bachelor of Engineering (B.E.) in Electrical, Electronics and Communications Engineering from Thakur College Of Engineering and Technology. Additionally, I completed a Post Graduate Diploma in Big Data Analytics from Imarticus Learning.</p>

          <h3>Skills</h3>
          <div className="skills">
            {skills.map((skill, index) => (
              <div key={index} className="skill">
                <span className="skill-name">{skill.name}</span>
                <ProgressBar now={skill.level} className="skill-bar" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
