import React from 'react';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import About from './components/About';
import WorkExperience from './components/WorkExperience';
import Education from './components/Education';
import Projects from './components/Projects';
import Contact from './components/Contact';
import './App.css';

function App() {
    return (
        <div className="App">
            <Header/>
            <LandingPage/>
            <About/>
            <WorkExperience/>
            <Education/>
            <Projects/>
            <Contact/>
        </div>
    );
}

export default App;
