import React from 'react';
import './Projects.css';

const projects = [
    {
        title: 'Detection and Classification of Brain Tumour from MRI Images',
        description: 'Detection of brain tumors using Image processing and Neural Networks.',
        duration: '6 months',
        technologies: 'MATLAB',
    },
    {
        title: 'Advanced Conversational AI for MyJio',
        description: 'Developed LiveChat functionality on the MyJio, JioChat mobile apps with 1 million conversations per day.',
        duration: '1 year',
        technologies: 'Python, Django, Docker',
    },
];

const Projects = () => {
    return (
        <section id="projects" className="projects">
            <div className="container">
                <h2 className="section-title">Projects</h2>
                <div className="projects-grid">
                    {projects.map((project, index) => (
                        <div className="project-card" key={index}>
                            <div className="card-content">
                                <h3 className="project-title">{project.title}</h3>
                                <p className="project-description">{project.description}</p>
                                <p className="project-duration">{project.duration}</p>
                                <p className="project-technologies">{project.technologies}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
