import React from 'react';
import './WorkExperience.css';

const experiences = [
  {
    company: 'Kreditserve',
    position: 'Head of Engineering',
    duration: 'January 2023 - Present (1 year 5 months)',
    location: 'Mumbai, Maharashtra, India',
    description: 'Leading a team of software engineers and architects to design, develop, and deploy scalable and secure solutions for the fintech industry.',
    active: true
  },
  {
    company: 'Haptik',
    positions: [
      {
        position: 'Software Engineering Manager',
        duration: 'April 2022 - September 2022 (6 months)',
      },
      {
        position: 'Associate Engineering Manager - Implementation - Jio',
        duration: 'April 2021 - April 2022 (1 year 1 month)',
      },
      {
        position: 'Integration Engineer',
        duration: 'October 2019 - March 2021 (1 year 6 months)',
      },
    ],
    location: 'Mumbai, Maharashtra, India',
    description: 'Managed a team of engineers to implement scalable solutions for Haptik’s clients.',
  },
  {
    company: 'Weird Labs Private Limited',
    position: 'Co-Founder, CTO',
    duration: 'April 2018 - December 2020 (2 years 9 months)',
    location: 'Mumbai, Maharashtra, India',
    description: 'Co-founded and led the technical direction of Weird Labs.',
  },
  {
    company: 'ZKTeco',
    position: 'Technical Lead',
    duration: 'May 2017 - September 2019 (2 years 5 months)',
    location: 'Mumbai, Maharashtra, India',
    description: 'Led technical projects and teams at ZKTeco.',
  },
  {
    company: 'Zycus',
    position: 'Remedyforce Expert',
    duration: 'April 2015 - April 2017 (2 years 1 month)',
    location: 'Mumbai, Maharashtra, India',
    description: 'Single handedly implemented BMC Remedyforce and Salesforce at Zycus according to ITIL guidelines and Migration of entire ticketing system from Salesforce to Remedyforce in including Lead management and Opportunities.',
  },
];

const WorkExperience = () => {
  return (
    <section id="work" className="work-experience">
      <div className="container">
        <h2 className="section-title">Work Experience</h2>
        <div className="experience-timeline">
          {experiences.map((exp, index) => (
            <div className={`experience-card ${exp.active ? 'active' : ''}`} key={index}>
              <div className="card-content">
                <h3 className="company">{exp.company}</h3>
                {Array.isArray(exp.positions) ? (
                  exp.positions.map((position, idx) => (
                    <div key={idx} className="position-section">
                      <h4 className="position">{position.position}</h4>
                      <p className="duration">{position.duration}</p>
                    </div>
                  ))
                ) : (
                  <div>
                    <h4 className="position">{exp.position}</h4>
                    <p className="duration">{exp.duration}</p>
                  </div>
                )}
                <p className="location">{exp.location}</p>
                <p className="description">{exp.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WorkExperience;
