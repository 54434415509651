// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: rgba(13, 13, 13, 0.27);
  color: #fff;
  padding: 20px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.name h1 {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.5em;
  margin: 0;
  color: #ff6f61;
  font-weight: bold;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav li {
  margin: 0 15px;
  font-weight: bold;
}

.nav a {
  color: #fff;
  text-decoration: none;
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #ff6f61;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,MAAM;EACN,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,SAAS;EACT,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,aAAa;AACf;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,8CAA8C;EAC9C,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".header {\n  background-color: rgba(13, 13, 13, 0.27);\n  color: #fff;\n  padding: 20px;\n  text-align: center;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  z-index: 1000;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.header-content {\n  max-width: 1200px;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 auto;\n}\n\n.name h1 {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.5em;\n  margin: 0;\n  color: #ff6f61;\n  font-weight: bold;\n}\n\n.nav ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n}\n\n.nav li {\n  margin: 0 15px;\n  font-weight: bold;\n}\n\n.nav a {\n  color: #fff;\n  text-decoration: none;\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.2em;\n  transition: color 0.3s ease;\n}\n\n.nav a:hover {\n  color: #ff6f61;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
