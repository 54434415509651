// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: 'Lato', 'Helvetica', 'sans-serif';
}

.landing-page {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.photo img {
    border-radius: 50%;
    border: 5px solid #FF6F61;
    width: 200px;
    height: 200px;
}

.intro {
    margin-top: 20px;
}

.title {
    font-family: 'Lato', 'Helvetica', 'sans-serif';
    font-size: 3em;
    color: #FFFFFF;
    margin: 20px 0;
}

.description {
    font-family: 'Lato', 'Helvetica', 'sans-serif';
    font-size: 1.2em;
    color: #CCCCCC;
    margin-bottom: 20px;
}

.icons a {
    color: #FF6F61;
    font-size: 2em;
    margin: 0 10px;
    transition: color 0.3s;
}

.icons a:hover {
    color: #FF8566;
}
`, "",{"version":3,"sources":["webpack://./src/components/LandingPage.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,cAAc;IACd,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["body {\n    margin: 0;\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n}\n\n.landing-page {\n    position: relative;\n    height: 100vh;\n    width: 100%;\n    background-color: #121212;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.overlay {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n}\n\n.photo img {\n    border-radius: 50%;\n    border: 5px solid #FF6F61;\n    width: 200px;\n    height: 200px;\n}\n\n.intro {\n    margin-top: 20px;\n}\n\n.title {\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n    font-size: 3em;\n    color: #FFFFFF;\n    margin: 20px 0;\n}\n\n.description {\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n    font-size: 1.2em;\n    color: #CCCCCC;\n    margin-bottom: 20px;\n}\n\n.icons a {\n    color: #FF6F61;\n    font-size: 2em;\n    margin: 0 10px;\n    transition: color 0.3s;\n}\n\n.icons a:hover {\n    color: #FF8566;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
