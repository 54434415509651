import React from 'react';
import './Contact.css';
import {FaEnvelope, FaGithub, FaLinkedin} from 'react-icons/fa';

const Contact = () => {
    return (
        <section id="contact" className="contact">
            <div className="container">
                <h2 className="section-title">Contact</h2>
                <div className="contact-details">
                    <p>If you have any questions or just want to get in touch, feel free to contact me via email or
                        through my social media channels.</p>
                    <div className="contact-info">
                        <p><FaEnvelope/> <a href="mailto:jeet.padhya@hotmail.com"
                                            target="_blank">jeet.padhya@hotmail.com</a></p>
                        <p><FaLinkedin/> <a href="https://www.linkedin.com/in/jeetpadhya" target="_blank"
                                            rel="noopener noreferrer">LinkedIn</a></p>
                        <p><FaGithub/> <a href="https://github.com/jeetpadhya" target="_blank"
                                          rel="noopener noreferrer">GitHub</a></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
