// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  background-color: #1a1a1a;
  color: #fff;
  padding: 60px 20px;
  text-align: left;
  min-height: 100vh;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.section-title {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.content p {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.content h3 {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #ff6f61;
}

.skills {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skill {
  flex: 0 0 48%;
  margin-bottom: 15px;
}

.skill-name {
  font-family: 'Lato', 'Helvetica', 'sans-serif';
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #fff;
}

.skill-bar {
  height: 20px;
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
}

.skill-bar .progress-bar {
  background-color: #ff6f61;
}
`, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".about {\n  background-color: #1a1a1a;\n  color: #fff;\n  padding: 60px 20px;\n  text-align: left;\n  min-height: 100vh;\n}\n\n.container {\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.section-title {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 2.5em;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.content p {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.2em;\n  line-height: 1.6;\n  margin-bottom: 20px;\n}\n\n.content h3 {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.8em;\n  margin-bottom: 10px;\n  color: #ff6f61;\n}\n\n.skills {\n  margin-top: 20px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.skill {\n  flex: 0 0 48%;\n  margin-bottom: 15px;\n}\n\n.skill-name {\n  font-family: 'Lato', 'Helvetica', 'sans-serif';\n  font-size: 1.2em;\n  margin-bottom: 5px;\n  color: #fff;\n}\n\n.skill-bar {\n  height: 20px;\n  background-color: #333;\n  border-radius: 5px;\n  overflow: hidden;\n}\n\n.skill-bar .progress-bar {\n  background-color: #ff6f61;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
