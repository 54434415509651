import React from 'react';
import {FaEnvelope, FaGithub, FaInstagram, FaLinkedin} from 'react-icons/fa';
import './LandingPage.css';
import profilePhoto from '../assets/profile.jpg'
const LandingPage = () => {
    return (<div className="landing-page">
        <div className="overlay">
            <div className="photo">
                <img src={profilePhoto} alt="Jeet Padhya"/>
            </div>
            <div className="intro">
                <h1 className="title">Jeet Padhya</h1>
                <p className="description">Head of Engineering @ Krediserve</p>
                <p className="description">Big Data Analytics Expert | Full Stack Developer |
                    System Architect | Product Developer | Finance Enthusiast</p>
                <div className="icons">
                    <a target="_blank" href="https://www.linkedin.com/in/jeetpadhya"  rel="noopener noreferrer">
                        <FaLinkedin/>
                    </a>
                    <a target="_blank" href="https://github.com/jeetpadhya" rel="noopener noreferrer">
                        <FaGithub/>
                    </a>
                    <a target="_blank" href="mailto:jeet.padhya@hotmail.com">
                        <FaEnvelope/>
                    </a>
                    <a target="_blank" href="https://instagram.com/bhendatakka" rel="noopener noreferrer">
                        <FaInstagram/>
                    </a>
                </div>
            </div>
        </div>
    </div>);
};

export default LandingPage;
