import React from 'react';
import './Education.css';

const educationList = [
    {
        institution: 'Thakur College Of Engineering and Technology',
        degree: 'Bachelor of Engineering (B.E.)',
        field: 'Electrical, Electronics and Communications Engineering',
        duration: '2010 - 2014',
    },
    {
        institution: 'Imarticus Learning',
        degree: 'Post Graduate Diploma',
        field: 'Big Data Analytics',
        duration: '2017',
    },
];

const Education = () => {
    return (
        <section id="education" className="education">
            <div className="container">
                <h2 className="section-title">Education</h2>
                <div className="education-timeline">
                    {educationList.map((edu, index) => (
                        <div className="education-card" key={index}>
                            <div className="card-content">
                                <h3 className="institution">{edu.institution}</h3>
                                <h4 className="degree">{edu.degree}</h4>
                                <p className="field">{edu.field}</p>
                                <p className="duration">{edu.duration}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Education;
