// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education {
    background-color: #1a1a1a;
    color: #fff;
    padding: 60px 20px;
    text-align: center;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

.section-title {
    font-family: 'Lato', 'Helvetica', 'sans-serif';
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ff6f61;
}

.education-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education-card {
    background-color: #121212;
    border: 1px solid #333;
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card-content {
    text-align: left;
}

.institution {
    font-family: 'Lato', 'Helvetica', 'sans-serif';
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #ff6f61;
    font-weight: bold;
}

.degree, .field, .duration {
    font-family: 'Lato', 'Helvetica', 'sans-serif';
    font-size: 1.2em;
    margin: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Education.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".education {\n    background-color: #1a1a1a;\n    color: #fff;\n    padding: 60px 20px;\n    text-align: center;\n}\n\n.container {\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.section-title {\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n    font-size: 2.5em;\n    margin-bottom: 20px;\n    color: #ff6f61;\n}\n\n.education-timeline {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.education-card {\n    background-color: #121212;\n    border: 1px solid #333;\n    border-radius: 10px;\n    margin: 20px 0;\n    padding: 20px;\n    width: 100%;\n    max-width: 600px;\n    transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.card-content {\n    text-align: left;\n}\n\n.institution {\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n    font-size: 1.5em;\n    margin-bottom: 10px;\n    color: #ff6f61;\n    font-weight: bold;\n}\n\n.degree, .field, .duration {\n    font-family: 'Lato', 'Helvetica', 'sans-serif';\n    font-size: 1.2em;\n    margin: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
